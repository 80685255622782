<template>
  <div class="quiz">
      <!-- <pre v-html="givenAnswers"></pre> -->

    <div class="quiz__questions" v-if="completedQuiz == false">
        <div v-for="(question, questionIndex) in questions" :key="question.id" :hidden="questionIndex != currentQuestion" :aria-hidden="questionIndex != currentQuestion">
            <div class="wpforms-field wpforms-field-radio">
                <label class="wpforms-field-label" for="wpforms-2420-field_21">{{ question.name }}</label>
                <ul>
                    <li :class="'choice-' + (questionIndex+1) + ' depth-1'" v-for="answer in question.answers" :key="answer.id">
                        <input v-model="givenAnswers[questionIndex]" :id="'quiz_' + question.id + '_' + answer.id" type="radio" :value="answer.id">
                        <label class="wpforms-field-label-inline" :for="'quiz_' + question.id + '_' + answer.id">{{ answer.name }}</label>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="quiz__results" v-if="showResult">
        <h3 class="padding-bottom-1">Je hebt de volgende optie<span v-if="matchingResults.length != 1">s</span>:</h3>
        <div class="quiz__result--default" v-if="matchingResults.length == 0">
            {{ noMatchesResult.description }}
            <div v-if="noMatchesResult.link" class="margin-top-1 margin-bottom-1">
                <ul class="is-style-arrows">
                    <li><strong><a :href="noMatchesResult.link.url" :target="noMatchesResult.link.target" style="text-decoration: underline">{{ noMatchesResult.link.title }}</a></strong></li>
                </ul>
            </div>
        </div>
        <div class="quiz__result" v-for="result in matchingResults" :key="result.term_id">
            <h4>{{ result.name }}</h4>
            {{ result.description }}
            <div v-if="result.link" class="margin-top-1 margin-bottom-1">
                <ul class="is-style-arrows">
                    <li><strong><a :href="result.link.url" :target="result.link.target" style="text-decoration: underline">{{ result.link.title }}</a></strong></li>
                </ul>
            </div>
        </div>
    </div>

    <nav class="quiz__navigation grid-x grid-margin-x padding-top-1 padding-bottom-1">
        <div class="cell auto" style="width: 50%" v-if="! completedQuiz">
            <span role="button" v-if="showPrevButton" class="prev-button" @click="handlePrevClick">Vorige</span>
        </div>
        <div class="cell shrink" v-if="showNextButton" style="width: 50%">
            <span role="button" class="next-button" @click="handleNextClick">Volgende</span>
        </div>
        <div class="cell shrink" v-if="showResultButton" style="width: 50%">
            <span role="button" class="result-button" @click="handleShowResultClick">Volgende</span>
        </div>
        <div class="cell auto" style="width: 50%">
            <span role="button" v-if="completedQuiz" class="retry-button" @click="handleRetryClick">Opnieuw</span>
        </div>
    </nav>

  </div>
</template>

<script>

// import quizData from '../assets/quizdata.json';

export default {
  name: 'Quiz',
  data() {
    return {
        fieldId: '',
        givenAnswers: [],
        demoAnswers: [36,40,42], // geeft resultaat 2 en 4
        currentQuestion: 0,
        questions: [],
        results: [],
        noMatchesResult: [],
        showResult: false,
        standalone: false,
    }
  },
  computed: {
      completedQuiz() {
          return this.givenAnswers.length == this.questions.length && this.showResult;
      },
      showResultButton() {
          return this.givenAnswers.length == this.questions.length && ! this.showResult;
      },
      showNextButton() {
          return this.currentQuestion == (this.givenAnswers.length-1) && this.currentQuestion < this.questions.length-1 && ! this.showResult;
      },
      showPrevButton() {
          return this.currentQuestion > 0 && ! this.showResult;
      },
      matchingResults() {
          return this.results.filter( result => result.combinations.find( combination => combination.every( v => this.givenAnswers.includes(v) ) ) );
      },
      defaultResult() {
          return this.results.filter( result => result.slug == 'niet-mogelijk' );
      }
  },
  methods: {
      handleNextClick() {
        this.currentQuestion++;
        this.showResult = false;
      },
      handleShowResultClick() {
          this.showResult = true;
      },
      handlePrevClick() {
        this.currentQuestion--;
        this.givenAnswers = this.givenAnswers.slice(0, this.currentQuestion + 1);
        this.showResult = false;
      },
      handleRetryClick() {
          this.givenAnswers = [];
          this.currentQuestion = 0;
          this.showResult = false;
      }
  },
  watch: {
      showResult( showResult ) {

        if ( this.standalone ) {
            return;
        }

        const quizField = document.getElementById('quiz').parentElement;
        const wpFormsFieldContainer = quizField.parentElement;
        const wpFormsForm = wpFormsFieldContainer.parentElement;
        const wpFormsSubmitContainer = wpFormsForm.querySelector('.wpforms-submit-container');


        if ( showResult ) {
            [].slice.call(document.querySelectorAll('.wpforms-field')).forEach( field => {
                if ( ! field.classList.contains('wpforms-field-quiz') ) {
                    field.classList.remove('hide');
                }
            });
            wpFormsSubmitContainer.classList.remove('hide');
        } else {
            [].slice.call(document.querySelectorAll('.wpforms-field')).forEach( field => {
                if ( ! field.classList.contains('wpforms-field-quiz') ) {
                    field.classList.add('hide');
                }
            });
            wpFormsSubmitContainer.classList.add('hide');
        }
      }
  },
  mounted() {
      if ( ! document.getElementById('quiz-data') ) {
          return;
      }

      const quizData = JSON.parse(document.getElementById('quiz-data').innerHTML);

      if ( ! quizData.standalone ) {
        const quizField = document.getElementById('quiz').parentElement;
        const wpFormsFieldContainer = quizField.parentElement;
        const wpFormsForm = wpFormsFieldContainer.parentElement;
        const wpFormsSubmitContainer = wpFormsForm.querySelector('.wpforms-submit-container');

        [].slice.call(wpFormsForm.querySelectorAll('.wpforms-field')).forEach( field => {
            if ( ! field.classList.contains('wpforms-field-quiz') ) {
                field.classList.add('hide');
            }
        });

        wpFormsSubmitContainer.classList.add('hide');
      }

      this.questions = quizData.questions;
      this.results = quizData.results;
      this.noMatchesResult = quizData.default;
      this.fieldId = quizData.fieldId;
      this.standalone = quizData.standalone;

    //   this.$nextTick(() => {
    //       console.log('givenAnswers', this.givenAnswers);
    //       document.getElementById( this.fieldId ).value = this.givenAnswers.join(',');
    //   });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

[role="button"] {
    display: inline-block;
    cursor: pointer;
    user-select: none;
}

</style>
